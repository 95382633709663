import { initAllowedCookiesDependencies, siteCookieLogicManager } from "./initCookieLogicManager.js";
import { initPageDataSettings } from "./initPageDataSettings.js";
import { getMcPageData } from "./McPageData.js";


export async function buildPageData() {
    const AppState = window.AppState;
    const server = "CUST";
    const urlPrefixesToRemove = ["customer"];

    initPageDataSettings({ server, urlPrefixesToRemove });

    await initAllowedCookiesDependencies();
    const PageData = getMcPageData();

    AppState.PageData = PageData;

    await siteCookieLogicManager.setPageCookies();

    return PageData;
}
import { GoogleConsentType  } from "./GoogleConsentTypes.js";
import { isBoolean, isNil, isNotNil, type Nil } from "@mcwd/typescript-type-guards";
import { googleConsentVerboseLogger as verboseLogger } from "./googleConsentLogger.js";

type GoogleConsentEntry = {
  implicit?: boolean;
  default?: boolean;
  quiet?: boolean;
  update?: boolean;
};

type TagDataEntries = {
  entries?: {
    [key in (GoogleConsentType | "region")]: key extends "region"? Record<string, any> : GoogleConsentEntry
  }
};

function getEntries(): TagDataEntries["entries"] | Nil {
  const google_tag_data = (window as any).google_tag_data as { ics?: TagDataEntries } | Nil;
  const entries = google_tag_data?.ics?.entries;
  return entries;
}

const getGrantedOrDenied = (value: unknown) => {
  return isBoolean(value) ? (value ? "granted" : "denied") : null;
};

const getColor = (value: unknown) => {
  if (isNil(value)) {
    return "";
  }
  return value === "granted" ? "color: #0C0" : "color: #C00";
};

const getValueFormattedLogText = (val: string | Nil, label: string) => {
  return isNotNil(val) ? `\n\t\t${label}: %c ${val}` : "%c";
};

export function readGoogleConsentEntriesGlobalVariable() {
  const entries = getEntries();
  if (isNil(entries)) {
    return null;
  }
  const result: Partial<{
    [key in (GoogleConsentType | "region")]: key extends "region"? Record<string, any> : GoogleConsentEntry
  }> = {};
  
  for (const consentType of Object.keys(entries) as (GoogleConsentType | "region")[]) {
    if (consentType === 'region') {
      result[consentType] = entries[consentType];
    }
    else {
      const entry = entries[consentType];
      if (isNil(entry.default) && isNil(entry.update)) continue;
      result[consentType] = { default: entry.default, update: entry.update };
    }
  }
  if (Object.keys(result).length > 0) {
    return result;
  }
  return null;
}

export function logGoogleConsentGlobalVarState() {
  try {
    const entries = readGoogleConsentEntriesGlobalVariable();
    if (isNil(entries)) {
      verboseLogger.warn("No Consent Mode data found");
      return;
    }
    verboseLogger.bindDebugOptions({formatString: "%cConsent Mode settings:"})("font-size: 1rem");
    for (const consentType of Object.keys(entries) as (GoogleConsentType | "region")[]) {
      if (consentType === "region") {
        verboseLogger.debug(`\t region: `, entries[consentType]);
      }
      else {
        const entry = entries[consentType] as { default: boolean, update: boolean };
        const defaultVal = getGrantedOrDenied(entry.default);
        const updateVal = getGrantedOrDenied(entry.update);
        const logText = `\t ${consentType}: ${getValueFormattedLogText(defaultVal, "Default")}%c${getValueFormattedLogText(updateVal, "Update")}`;
        verboseLogger.bindDebugOptions({ formatString: logText })(getColor(defaultVal), "", getColor(updateVal));
      }
    }
  }
  catch(err) {
    verboseLogger.warn("Error in log Google Consent", err);
  }
}

Object.assign(window, { readGoogleConsentEntriesGlobalVariable, logGoogleConsentGlobalVarState });
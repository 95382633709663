import { isNil, type Nil } from "@mcwd/typescript-type-guards";
import { OneTrustCategoryId, OneTrustCategoryNameToIdMapping } from "./OneTrustCategoryIdToNameMapping.js";

const strictlyNecessaryId = OneTrustCategoryNameToIdMapping["Strictly Necessary"];
const initAndNormalizeOneTrustCategoryIdList = (ids: OneTrustCategoryId[]): OneTrustCategoryId[] => {
  if (ids.includes(strictlyNecessaryId) === false) {
    // This might only be missing if loading an old OT version cookie.
    ids.push(strictlyNecessaryId);
  }
  // Sort the values so they can be compared for equalty on update.
  ids.sort();
  return ids;
};

export class EnabledOneTrustCategoryIdState {
  #ids: OneTrustCategoryId[];

  constructor(ids: OneTrustCategoryId[]) {
    this.#ids = ids;
  }

  get CurrentIds(): OneTrustCategoryId[] {
    return this.#ids;
  }

  set CurrentIds(val: OneTrustCategoryId[]) {
    this.#ids = initAndNormalizeOneTrustCategoryIdList(val);
  }

  checkUpdateEqualsCurrent(updatedCategoryIds: OneTrustCategoryId[] | Nil) {
    const updatedCategoryIdsNormalized = isNil(updatedCategoryIds)
      ? [strictlyNecessaryId]
      : initAndNormalizeOneTrustCategoryIdList(updatedCategoryIds);

    const curIds = this.#ids;
    if (updatedCategoryIdsNormalized.length !== curIds.length) {
      return false;
    }
    return updatedCategoryIdsNormalized.every((updatedId, idx) => {
      return curIds[idx] === updatedId;
    });
  }
}
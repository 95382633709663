import { isFunction } from "@mcwd/typescript-type-guards";
import { LocaleConvert, type SiteCountryCodeUppercase } from "../../../types-and-definitions/locale-defs/Locales.js";
import { GoogleTagManagerWrapper } from "../Wrapper/GoogleTagManagerWrapper.js";
import type { McPageData } from "../../page-data/McPageData.js";
import type { NewTagManagerWrapperArgs } from "../Wrapper/TagManagerWrapper.js";

function getTagManagerArgs(pageData: McPageData, siteCountryCode?: SiteCountryCodeUppercase): NewTagManagerWrapperArgs {
    const Country = siteCountryCode ?? window.AppState.GetCurrentLanguageCode();
    const Language = LocaleConvert.FromSiteCountryCode.ToLanguageCountryLocale(Country, true);
    const { PageName, UrlData, CategoryPath, SfTemplateName: PageType } = pageData;
    const { UtmCampaign = "", UtmMedium = "", UtmSource = "" } = pageData;
    const { PageUrl } = UrlData;

    const rawData: string[] = [];
    if (isFunction(window.AppState.WidgetData?.__getRawWidgetData)) {
        window.AppState.WidgetData.__getRawWidgetData()
            .forEach(({ widget: { variation, name } }) => {
                rawData.push(`${ variation ?? "undefined" }|${name}`);
            });
    }
    const Widgets = rawData.join(",");

    return {
        CategoryPath,
        Language,
        Country,
        PageType,
        PageUrl,
        PageName,
        UtmCampaign,
        UtmMedium,
        UtmSource,
        Widgets,
    };
}

function setupDataLayerAndTm(pageData: McPageData, siteCountryCode?: SiteCountryCodeUppercase){
    const tmArgs = getTagManagerArgs(pageData, siteCountryCode);
    const McTagManager = new GoogleTagManagerWrapper(tmArgs);

    window.McTagManager = McTagManager;
    if (isFunction(window.LoadTagManager)) {
        window.LoadTagManager();
    } else {
        console.error("window.LoadTagManager() does not exist");
    }
}

function LoadTagManager(){
    if (("LoadGtm" in window) && isFunction(window.LoadGtm)){
        window.LoadGtm();
    }
}

window.LoadTagManager = window.LoadTagManager ?? LoadTagManager;

let waitForDocumentInteractivePromise: Promise<void> | null = null;
async function waitForDocumentInteractive(){
    waitForDocumentInteractivePromise ??= new Promise<void>(accept => {
        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'interactive') {
                accept();
            }
        });
        if (document.readyState === "interactive" || document.readyState === "complete"){
            console.log("Document already in state: " + document.readyState);
            accept();
        }
    });
    return await waitForDocumentInteractivePromise;
}

export async function setupDataLayerAndWrapTagManager(pageData: McPageData, siteCountryCode?: SiteCountryCodeUppercase){
    await waitForDocumentInteractive();
    setupDataLayerAndTm(pageData, siteCountryCode);
}
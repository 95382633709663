import { isArray } from "@mcwd/typescript-type-guards";
import { SiteCookie, type CookieWriteOptions } from "./SiteCookie.js";
import { verboseCookiesLogger as verboseLogger } from "./siteCookiesLogger.js";
import { ValueGetters } from "../data/ValueGetters.js";
import type { CookieValueType } from "../../utils/cookieUtils.js";

export type SiteCookiesArray<T extends CookieValueType = CookieValueType> = SiteCookie<T>[];

export function getNamedCookieFromArray<T extends CookieValueType = CookieValueType>(siteCookies: SiteCookiesArray<T>, name: string) {
  return siteCookies.find(c => c.name === name);
}

type CookieUpdateListBase = {
	[key: string]: SiteCookiesArray
};

export type CookieUpdateLists = CookieUpdateListBase & { All: SiteCookiesArray };
export class SiteCookieLogicManager {
	#updateLists: CookieUpdateListBase = {};

	#getFilteredUpdateListsObject(status: SiteCookie["status"] | Array<SiteCookie["status"]>) {
		const filteredUpdatesObj = {} as CookieUpdateLists;
		for (const key of Object.keys(this.#updateLists)) {
			const completedUpdates = isArray(status)? this.#updateLists[key].filter(c => status.includes(c.status)) :  this.#updateLists[key].filter(c => c.status === status);
			filteredUpdatesObj[key] = completedUpdates;
		}
		return filteredUpdatesObj;
	}
	#getAllCookieUpdatesArray(status:SiteCookie["status"] | Array<SiteCookie["status"]>): SiteCookie<CookieValueType>[] {
		return Object.values(this.#getFilteredUpdateListsObject(status)).flat();
	}

	#getCookieUpdateLists(status: SiteCookie["status"] | Array<SiteCookie["status"]>) {
		return { ... this.#getFilteredUpdateListsObject(status), All: this.#getAllCookieUpdatesArray(status) };
	}

	get updateLists(): CookieUpdateLists {
		return this.#getCookieUpdateLists(["written", "written-partial"]);
	}

	get cookiesAwaitingConsentLists(): CookieUpdateLists {
		return this.#getCookieUpdateLists("awaiting-consent");
	}

	async tryUpdateCookiesAwaitingConsent() {
		const cookiesToUpdate = this.cookiesAwaitingConsentLists.All;
		verboseLogger.debug("tryUpdateCookiesAwaitingConsent", { cookiesToUpdate });
		const updatedCookies: SiteCookie<CookieValueType>[] = [];
		if (cookiesToUpdate.length > 0) {
			for await (const c of cookiesToUpdate) {
				if (await c.checkUserConsented()) {
					await c.setQueuedValueIfConsentGiven();
					updatedCookies.push(c);
				}
			}
			verboseLogger.debug("tryUpdateCookiesAwaitingConsent", {updatedCookies});
		}
		return updatedCookies;
	}

	addCookiesToUpdateList(listName: string, ...cookies: SiteCookie[]) {
		const statusesToAdd: Array<SiteCookie["status"]> = ["written", "written-partial", "awaiting-consent"];
		cookies = cookies.filter(c => statusesToAdd.includes(c.status));
		verboseLogger.debug("addCookiesToUpdateList", { cookies });
		if (cookies.length > 0) {
			verboseLogger.debug(`Cookie Updates (${listName}): `, cookies);
			this.#updateLists[listName] ??= [];
			this.#updateLists[listName] = this.#updateLists[listName].concat(cookies);
		}
		return cookies;
	}

	async setSourceCookies() {
		if (this.#updateLists.source) {
			return this.#updateLists.source;
		}

		// ENTRYPAGE: reset for every session
		const userEntryPage = new SiteCookie({ name: ValueGetters.EntryPage.CookieName, cookieCategory: "Performance" });
		await userEntryPage.set(ValueGetters.EntryPage.getValueFromGetterFn() ?? "", {
			overwriteExisting: false,
			allowEmpty: true
		});

		const utmValueGetters = [ValueGetters.UtmCampaign, ValueGetters.UtmMedium, ValueGetters.UtmSource];

		const utmCookieList: SiteCookie<string>[] = [];
		for await (const getter of utmValueGetters){
			// set anytime this queryParameter appears in the url
			const cookie = new SiteCookie<string>({ name: getter.CookieName, cookieCategory: "Targeting" });
			await cookie.set((getter.getValueFromQuery() ?? ""), {
				overwriteExisting: true,
				allowEmpty: false,
				expires: 30
			});
			utmCookieList.push(cookie);
		}

		

		// source_string: set anytime the referrer is an external url
		const sourceString = new SiteCookie({ name: ValueGetters.ExternalReferrer.CookieName, cookieCategory: "Performance" });
		const sourceStringWriteOpts: CookieWriteOptions = {
			overwriteExisting: true,
			allowEmpty: false,
			expires: 30
		};

		await sourceString.set(ValueGetters.ExternalReferrerDomainOnly.getValueFromGetterFn() ?? "", { ... sourceStringWriteOpts, setPartialData: true });
		await sourceString.set(ValueGetters.ExternalReferrer.getValueFromGetterFn() ?? "", { ...sourceStringWriteOpts, setPartialData: false });

		const gclid = new SiteCookie({ name: ValueGetters.Gclid.CookieName, cookieCategory: "Targeting" });
		const gclidValue = ValueGetters.Gclid.getValueFromGetterFn();
		if (gclidValue) {
			await gclid.set(JSON.stringify(gclidValue), {
				overwriteExisting: true,
				allowEmpty: false,
				expires: 45
			});
		}

		return this.addCookiesToUpdateList("source", ...utmCookieList, userEntryPage, sourceString, gclid);
	}

	async setPageCookies() {
		if (this.updateLists.page) {
			return this.updateLists.page;
		}
		const pageNameCookie = new SiteCookie({ name: ValueGetters.PageName.CookieName, cookieCategory: "Performance" });
		await pageNameCookie.set(ValueGetters.PageName.getValueFromGetterFn() ?? "", { overwriteExisting: true, allowEmpty: false });
		return this.addCookiesToUpdateList("page", pageNameCookie);
	}
}
import { isNil, isNotNil, isString, type Nil } from "@mcwd/typescript-type-guards";
import { getCookie, type CookieReadOptions, type CookieAttributes, setCookie, type CookieValueType } from "../../utils/cookieUtils.js";
import type { OneTrustCategoryName } from "../onetrust/OneTrustCategoryIdToNameMapping.js";
import { getOneTrustValues } from "../onetrust/getOneTrustValues.js";
import { verboseCookiesLogger as verboseLogger } from "./siteCookiesLogger.js";

export const SiteCookieUpdatedEventName = "SiteCookieUpdated" as const;
export type SiteCookieUpdatedEvent = CustomEvent<{
    cookieName: string;
    value: Nil | CookieValueType;
    updateType: "full" | "partial";
}>;

function dispatchSiteCookieUpdatedEvent(cookieName: string, cookieValue: CookieValueType | Nil, updateType: "full" | "partial") {
    verboseLogger.info("Dispatch event 'SiteCookieUpdated' on window", { detail: { cookieName, value: cookieValue, updateType } });
    window.dispatchEvent(new CustomEvent(SiteCookieUpdatedEventName, { detail: { cookieName, value: cookieValue, updateType } }));
}


// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const COOKIE_CONSENT_CHECK_ENABLED: boolean = false;

function valIsEmpty(val: unknown): val is (Nil | "") {
    val = isString(val) ? val.trim() : val;
    return (val ?? "") === "";
}

type CookieValuesObject<T extends CookieValueType> = {
    initial?: T | Nil;
    updated?: T | Nil;
    updatedPartial?: T | Nil;
    updateAwaitingConsent?: T | Nil
}

export type CookieWriteOptions = {
    overwriteExisting?: boolean;
    allowEmpty?: boolean;
    setPartialData?: boolean;
} & CookieAttributes;

export class SiteCookie<T extends CookieValueType = string> {
    #name: string;
    #cookieCategory: OneTrustCategoryName;
    #readOptions?: CookieReadOptions<T>;
    #partialWriteOptions?: CookieWriteOptions & { setPartialData: true };
    #fullWriteOptions?: CookieWriteOptions & { setPartialData?: false };

    #values: CookieValuesObject<T>;

    static get PartialEnabled(): boolean {
        return COOKIE_CONSENT_CHECK_ENABLED;
    }

    constructor({ name, readOptions, cookieCategory }: { name: string, cookieCategory: OneTrustCategoryName, readOptions?: CookieReadOptions<T> }) {
        this.#name = name;
        this.#cookieCategory = cookieCategory;
        this.#readOptions = readOptions;
        this.#values = {
            initial: getCookie<T>(name, this.readOptions)
        };
    }

    async checkUserConsented() {
        if (COOKIE_CONSENT_CHECK_ENABLED === false) {
            return true;
        }
        const onetrustValues = await getOneTrustValues();
        if (onetrustValues?.AwaitingReconsent) {
            return false;
        }
        const matchingGroup = onetrustValues?.groups?.find(g => g.name === this.#cookieCategory);
        return matchingGroup?.enabled === true;
    }

    get name(): string {
        return this.#name;
    }
    get cookieCategory(): OneTrustCategoryName {
        return this.#cookieCategory;
    }
    get readOptions(): CookieReadOptions<T> | undefined {
        return this.#readOptions;
    }
    get writeOptions(): Readonly<(CookieWriteOptions & { setPartialData: boolean })> | Nil {
        const writeOpts = isNotNil(this.#fullWriteOptions) ? this.#fullWriteOptions : this.#partialWriteOptions;
        if (isNil(writeOpts)) {
            return null;
        }
        writeOpts.setPartialData ??= false;
        return Object.freeze({ ...writeOpts } as (CookieWriteOptions & { setPartialData: boolean }));
    }

    #setWriteOptions(writeOptions: CookieWriteOptions) {
        if (writeOptions.setPartialData === true) {
            this.#partialWriteOptions = writeOptions as (CookieWriteOptions & {setPartialData: true});
        }
        else {
            this.#fullWriteOptions = writeOptions as (CookieWriteOptions & {setPartialData?: false});
        }
    }

    get values(): Readonly<CookieValuesObject<T>> {
        return Object.freeze({ ... this.#values });
    }

    get status(): "new" | "written-partial" | "awaiting-consent" | "written" {
        if (isNotNil(this.#values.updated)) return "written";
        if (isNotNil(this.#values.updateAwaitingConsent)) return "awaiting-consent";
        if (isNotNil(this.#values.updatedPartial)) return "written-partial";
        return "new";
    }
    async set<
        TAllowEmpty extends boolean,
        TSetValue extends (TAllowEmpty extends true? (T | undefined) : T)
    >(
        value: TSetValue, 
        writeOptions: CookieWriteOptions = {}
    ) {
        this.#setWriteOptions(writeOptions);
        const { overwriteExisting = true, allowEmpty, setPartialData = false, ...CookieAttrs } = writeOptions;
        if (overwriteExisting !== true && isNotNil(this.#values.initial)) {
            return;
        }
        if (valIsEmpty(value) && !allowEmpty) {
            return;
        }
        if (setPartialData) {
            setCookie(this.name, value, CookieAttrs);
            this.#values.updatedPartial = value;
            dispatchSiteCookieUpdatedEvent(this.name, value, "partial");
        }
        else {
            const haveConsent = await this.checkUserConsented();
            if (haveConsent) {
                setCookie(this.name, value, CookieAttrs);
                this.#values.updated = value;
                dispatchSiteCookieUpdatedEvent(this.name, value, "full");
            }
            else {
                this.#values.updateAwaitingConsent = value;
            }
        }
    }
    async setQueuedValueIfConsentGiven() {
        if (isNil(this.#values.updated) && isNotNil(this.#values.updateAwaitingConsent) && isNotNil(this.#fullWriteOptions)) {
            const haveConsent = await this.checkUserConsented();
            if (haveConsent) {
                const value = this.#values.updateAwaitingConsent;
                const { overwriteExisting: _oe, allowEmpty: _ae, setPartialData: _spd, ...CookieAttrs } = this.#fullWriteOptions;
                setCookie(this.name, value, CookieAttrs);
                this.#values.updated = value;
                delete this.#values.updateAwaitingConsent;
                dispatchSiteCookieUpdatedEvent(this.name, value, "full");
            }
        }
    }
}
import { SiteCookieLogicManager, type SiteCookiesArray, getNamedCookieFromArray } from "../cookies/SiteCookieLogicManager.js";

export const siteCookieLogicManager = new SiteCookieLogicManager();
window.AppState.siteCookieLogicManager = siteCookieLogicManager;

export async function initAllowedCookiesDependencies() {
  await siteCookieLogicManager.setSourceCookies();
  const cookieResults = siteCookieLogicManager.updateLists.All;
  return cookieResults;
}

export async function setCookiesIfConsentUpdated() {
  return await siteCookieLogicManager.tryUpdateCookiesAwaitingConsent();
}

export function getPageDataFieldsFromCookies(siteCookies: SiteCookiesArray) {
  const UtmCampaign = getNamedCookieFromArray(siteCookies, 'utm_campaign')?.values?.updated as string | undefined;
  const UtmMedium = getNamedCookieFromArray(siteCookies, 'utm_medium')?.values?.updated as string | undefined;
  const UtmSource = getNamedCookieFromArray(siteCookies, 'utm_source')?.values?.updated as string | undefined;

  const result: { UtmCampaign?: string, UtmMedium?: string, UtmSource?: string } = {};
  if (UtmCampaign) result.UtmCampaign = UtmCampaign;
  if (UtmMedium) result.UtmMedium = UtmMedium;
  if (UtmSource) result.UtmSource = UtmSource;
  return result;
}
import { exhaustiveGuard, isNotNil, type Nil } from "@mcwd/typescript-type-guards";
import type { OptanonConsentCookieObject } from "../onetrust/OptanonContentCookieTypes.js";
import { type GoogleConsentDefaults, GoogleConsentStatus, GoogleConsentType } from "./GoogleConsentTypes.js";
import { GdprCountryCode } from "../region/GdprCountries.js";

import { getGoogleConsentFromOneTrust } from "./getGoogleConsentFromOneTrust.js";

function createGoogleConsentDefaultWithSingleStatus(status: GoogleConsentStatus): GoogleConsentDefaults {
  return GoogleConsentType.reduce((result, key) => {
    result[key] = status;
    return result;
  }, {}) as { [key in GoogleConsentType]: typeof status };
}

const DEFAULT_WAIT_FOR_UPDATE_MS = 1000;
const allDeniedDefault = createGoogleConsentDefaultWithSingleStatus('denied');
const allGrantedDefault = createGoogleConsentDefaultWithSingleStatus('granted');
export interface GetGoogleConsentParams {
  consentCookie: OptanonConsentCookieObject | Nil,
  addRegionDefaults: boolean,
  globalDefaultValue: GoogleConsentStatus;
  waitForUpdateInMs?: number | Nil
} 
export function getGoogleConsentDefaults({
    consentCookie,
    addRegionDefaults,
    globalDefaultValue,
    waitForUpdateInMs = DEFAULT_WAIT_FOR_UPDATE_MS
  }: GetGoogleConsentParams
): GoogleConsentDefaults[]
{
  const consentDefaults: GoogleConsentDefaults[] = [];

  const wait_for_update = ((waitForUpdateInMs ?? -1) >= 0 ? waitForUpdateInMs : null) ?? DEFAULT_WAIT_FOR_UPDATE_MS;
  const waitForUpdateOption = { wait_for_update };

  if (isNotNil(consentCookie)) {
    const userDefaults = getGoogleConsentFromOneTrust(consentCookie) as GoogleConsentDefaults;
    // Return user preferences saved in the OneTrust cookie
    if (isNotNil(userDefaults)) {
      if (addRegionDefaults) {
        consentDefaults.push({ ...userDefaults, ...waitForUpdateOption, region: GdprCountryCode });
      }
      consentDefaults.push({ ...userDefaults, ...waitForUpdateOption });
      return consentDefaults;
    }
  }

  if (addRegionDefaults) {
    consentDefaults.push({ ...allDeniedDefault, ...waitForUpdateOption, region: GdprCountryCode });
    // Add more regions here as needed
  }

  // Set global default value
  if (globalDefaultValue === "granted") {
    consentDefaults.push({ ...allGrantedDefault, ...waitForUpdateOption });
  }
  else if(globalDefaultValue === "denied") {
    consentDefaults.push({ ...allDeniedDefault, ...waitForUpdateOption });
  }
  else {
    throw exhaustiveGuard(globalDefaultValue);
  }
  return consentDefaults;
}
export interface McUrlData {
    PageUrl: string;
    FullPagePath: string;
    PagePath: string;
    PagePathArray: string[];
}

function normalizeUrlPathArray({ PagePathArray, urlPrefixesToRemove }: {
    PagePathArray: string[],
    urlPrefixesToRemove: string[]
}) {
    if (urlPrefixesToRemove.includes(PagePathArray[0])) {
        PagePathArray.shift(); // remove prefixes to normalize path
    }
    return PagePathArray;
}

export function getMcUrlData({ urlPrefixesToRemove }: { urlPrefixesToRemove: string[] }): McUrlData {
    let { origin, pathname } = window.document.location;
    pathname = pathname.toLowerCase();
    origin = origin.toLowerCase();
    pathname = pathname.replace(/\/?$/, "/").replace(/\/+/, "/"); // Ensure the path ends with '/' and remove repeating '/'s

    const PageUrl: string = origin + pathname;
    const FullPagePath: string = pathname;
    let PagePathArray: string[] = pathname.split("/").filter(el => el !== "");
    PagePathArray = normalizeUrlPathArray({ PagePathArray, urlPrefixesToRemove });
    const PagePath: string = PagePathArray.join("/");

    return {
        PageUrl,
        FullPagePath,
        PagePath,
        PagePathArray
    };
}
import { OneTrustCategoryName, OneTrustCategoryNameToIdMapping } from "../onetrust/OneTrustCategoryIdToNameMapping.js";

type GtagConsentStorageOptionsOnly = Omit<Gtag.ConsentParams, "wait_for_update" | "region">;
type GtagConsentParamGroupName = (keyof GtagConsentStorageOptionsOnly);

type GtagConsentStorageValueOptions = Required<GtagConsentStorageOptionsOnly>[GtagConsentParamGroupName];

const google_consent_types = [
  "ad_storage",
  "ad_user_data",
  "ad_personalization",
  "analytics_storage",
  "functionality_storage",
  "personalization_storage",
  "security_storage"
] as const satisfies readonly GtagConsentParamGroupName[];
type google_consent_types = typeof google_consent_types[number];

const google_consent_type_to_cookie_category_mapping = {
  "ad_storage": "Targeting",
  "ad_user_data": "Targeting",
  "ad_personalization": "Targeting",
  "analytics_storage": "Performance",
  "functionality_storage": "Functional",
  "personalization_storage": "Functional",
  "security_storage": "Functional"
} as const satisfies { [key in (google_consent_types & GtagConsentParamGroupName)]: OneTrustCategoryName };


const google_consent_status = ["granted", "denied"] as const satisfies readonly GtagConsentStorageValueOptions[];

Object.freeze(google_consent_types);
Object.freeze(google_consent_type_to_cookie_category_mapping);
Object.freeze(google_consent_status);

export const GoogleConsentType = google_consent_types;
export type GoogleConsentType = google_consent_types;

export const GoogleConsentTypeToCookieCategoryMapping = google_consent_type_to_cookie_category_mapping;
export type GoogleConsentTypeToCookieCategoryMapping = typeof google_consent_type_to_cookie_category_mapping;

export type GoogleConsentTypeToCookieCategoryIdMapping = {
  [GCT in GoogleConsentType]: OneTrustCategoryNameToIdMapping[GoogleConsentTypeToCookieCategoryMapping[GCT]]
};
export const GoogleConsentTypeToCookieCategoryIdMapping = Object.fromEntries(
  GoogleConsentType.map((gct: GoogleConsentType) => {
    const otCategoryName = GoogleConsentTypeToCookieCategoryMapping[gct];
    const otCategoryId = OneTrustCategoryNameToIdMapping[otCategoryName];
    return [gct, otCategoryId] as [GoogleConsentType, (typeof otCategoryId)];
  })
) as GoogleConsentTypeToCookieCategoryIdMapping;

export type OneTrustCategoryIdsPossibleForGoogleConsentGroups = GoogleConsentTypeToCookieCategoryIdMapping[GoogleConsentType];
export const OneTrustCategoryIdsPossibleForGoogleConsentGroups = GoogleConsentType.map(gct => {
  return GoogleConsentTypeToCookieCategoryIdMapping[gct];
}) as OneTrustCategoryIdsPossibleForGoogleConsentGroups[];

export const GoogleConsentStatus = google_consent_status;
export type GoogleConsentStatus = typeof google_consent_status[number];

type google_consent_options_base = { [key in GoogleConsentType]: GoogleConsentStatus };

export type GoogleConsentOptions = google_consent_options_base & {
  region?: string[];
}

export type GoogleConsentDefaults = GoogleConsentOptions & {
  wait_for_update?: number;
}
const gdpr_countries = [
  { name: "Austria", code: 'AT' },
  { name: "Belgium", code: 'BE' },
  { name: "Bulgaria", code: 'BG' },
  { name: "Croatia", code: 'HR' },
  { name: "Cyprus", code: 'CY' },
  { name: "Czech Republic", code: 'CZ' },
  { name: "Denmark", code: 'DK' },
  { name: "Estonia", code: 'EE' },
  { name: "Finland", code: 'FI' },
  { name: "France", code: 'FR' },
  { name: "Germany", code: 'DE' },
  { name: "Greece", code: 'GR' },
  { name: "Hungary", code: 'HU' },
  { name: "Iceland", code: 'IS' },
  { name: "Ireland", code: 'IE' },
  { name: "Italy", code: 'IT' },
  { name: "Latvia", code: 'LV' },
  { name: "Liechtenstein", code: 'LI' },
  { name: "Lithuania", code: 'LT' },
  { name: "Luxembourg", code: 'LU' },
  { name: "Malta", code: 'MT' },
  { name: "Netherlands", code: 'NL' },
  { name: "Norway", code: 'NO' },
  { name: "Poland", code: 'PL' },
  { name: "Portugal", code: 'PT' },
  { name: "Romania", code: 'RO' },
  { name: "Slovakia", code: 'SK' },
  { name: "Slovenia", code: 'SI' },
  { name: "Spain", code: 'ES' },
  { name: "Sweden", code: 'SE' },
  { name: "United Kingdom", code: 'GB' }
] as const;

const gdpr_country_name = gdpr_countries.map(c => c.name);
const gdpr_country_code = gdpr_countries.map(c => c.code);

Object.freeze(gdpr_countries);
Object.freeze(gdpr_country_name);
Object.freeze(gdpr_country_code);

export const GdprCountries = gdpr_countries;
export type GdprCountries = typeof gdpr_countries[number];

export type GdprCountryName = typeof gdpr_country_name[number];
export const GdprCountryName = gdpr_country_name;

export type GdprCountryCode = typeof gdpr_country_code[number];
export const GdprCountryCode = gdpr_country_code;
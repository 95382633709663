import { buildPageData } from './page-data/BuildCustomerPageData.js';
import { setupDataLayerAndWrapTagManager } from "./TagManager/LoadScript/setupDataLayerAndWrapTagManager.js";

import { setupGoogleConsent } from './google-consent/setupGoogleConsent.js';
import { type Nil } from '@mcwd/typescript-type-guards';
import { parseBool } from '@mcwd/util-parse-and-convert';
import { Logger } from "../utils/Logger/Logger.js";

const logger = new Logger({ tags: { category: "SetupCustomer" } });
const verboseLogger = logger.withTags({ verboseDebug: "SetupCustomer" });

const currentScript = window.document.currentScript as HTMLScriptElement | Nil;
const currentScriptUrlParams = new URLSearchParams(currentScript?.src?.substring(currentScript.src.indexOf("?")));
const readBoolParam = (param: string, defaultVal = true) => (parseBool(currentScriptUrlParams.get(param), { parseFromNumber: true }) ?? defaultVal);

const USE_GOOGLE_CONSENT = readBoolParam("use_google_consent");

async function setupCustomer() {
  const pageData = await buildPageData();
  verboseLogger.debug("Setup Customer: ", {
    USE_GOOGLE_CONSENT
  });
  if (USE_GOOGLE_CONSENT) {
    const gcSettings = {
      addRegionDefaults: false,
      globalDefaultValue: "granted" as const,
      readDefaultsFromCookie: false,
      waitForUpdateInMs: 0,
      enableConsentUpdateEvent: false
    };
    verboseLogger.debug("Google Consent Settings: ", gcSettings);
    await setupGoogleConsent(gcSettings);
  }
  await setupDataLayerAndWrapTagManager(pageData, "US");
}

setupCustomer().catch(logger.error);
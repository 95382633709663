import type { TagManagerWrapper, TagManagerEvent, NewTagManagerWrapperArgs } from "./TagManagerWrapper.js";

type WindowWithDataLayer = typeof window & { dataLayer: object[] };

(window as WindowWithDataLayer).dataLayer = (window as WindowWithDataLayer).dataLayer || [];

export class GoogleTagManagerWrapper implements TagManagerWrapper {
  #name = "gtm";
  __eventsSent: TagManagerEvent[] = [];
  constructor(pageLoadArgs: NewTagManagerWrapperArgs) {
    const { CategoryPath, PageType, PageUrl, PageName, Widgets } = pageLoadArgs;
    const { Language, Country, UtmCampaign, UtmMedium, UtmSource } = pageLoadArgs;

    const initialDataLayerObj = {
      SiteHierarchy: CategoryPath,
      Category: CategoryPath[0],
      Subcategory: CategoryPath[1] ?? "",
      Language: Language,
      Country: Country,
      PageType: PageType,
      PageUrl: PageUrl,
      PageName: PageName,
      Campaign: UtmCampaign,
      Medium: UtmMedium,
      Source: UtmSource,
      Widgets: Widgets,
    };
    this.addToDataLayer(initialDataLayerObj);
  }

  get name(){
    return this.#name;
  }

  addToDataLayer(valuesToAdd: Record<string, any>){
    this.DataLayer.push(valuesToAdd);
  }

  sendEvent(eventObj: TagManagerEvent){
    try {
      this.addToDataLayer(eventObj);
      this.__eventsSent.push(eventObj);
      console.debug(`Event Sent: "${eventObj.event}" %O`, eventObj);
    }
    catch (e) {
      console.warn(`GoogleTagManagerWrapper.sendEvent failed! \nEvent<${eventObj.event}>:\n%O. \nException:\n%O`, eventObj, e);
    }
  }

  get DataLayer(): object[] {
    return (window as WindowWithDataLayer).dataLayer as Record<string, any>[];
  }
}
import type { Nil } from "@mcwd/typescript-type-guards";
import { ValueGetters } from "../data/ValueGetters.js";
import { type McUrlData } from "./McUrlData.js";
import { PageDataSettings } from "./initPageDataSettings.js";
import { getPageName } from "./getPageName.js";

export type McPageData = {
    UrlData: McUrlData,
    Server: string,
    PageName: string,
    SfTemplateName: string,
    UtmCampaign?: string | Nil,
    UtmMedium?: string | Nil,
    UtmSource?: string | Nil,
    CategoryPath: string[],
    Channel: string,
    Silo: string,
    SDirectory: string
}

function getSDirectory(pagePathArray: string[]){
    const pagePathLength = pagePathArray.length;
    // sDirectory is "(root)" if this is the home page
    if (pagePathLength === 0){
        return "(root)"; // Home Page
    }
    // sDirectory is the current page if this is a top-level page
    else if (pagePathLength === 1){
        return pagePathArray[0]; // root level pages.
    }
    // sDirectory is the parent page of the current page if this is a nested page
    else { // pagePathLength >=2
        return pagePathArray[pagePathLength - 2];
    }
}

function buildMcPageData({
        Server,
        UtmCampaign,
        UtmMedium,
        UtmSource,
        SfTemplateName = "",
        UrlData
    }: {
        Server: string,
        UtmCampaign?: string | Nil,
        UtmMedium?: string | Nil,
        UtmSource?: string | Nil,
        SfTemplateName?: string | Nil,
        UrlData: McUrlData
    }
): McPageData 
{
    SfTemplateName = (SfTemplateName ?? "").trim();

    const pagePathLength = UrlData.PagePathArray.length;

    const PageName = getPageName();

    const CategoryPath = [
        (pagePathLength > 0? UrlData.PagePathArray : "home")
    ].flat();

    const Channel = pagePathLength > 0
        ? UrlData.PagePathArray[0]
        : "(root)";

    const Silo = pagePathLength > 0 
        ? UrlData.PagePathArray[0]
        : "Home";

    const SDirectory = getSDirectory(UrlData.PagePathArray);

    return {
        UrlData,
        Server,
        PageName,
        SfTemplateName,
        UtmCampaign,
        UtmMedium,
        UtmSource,
        CategoryPath,
        Channel,
        Silo,
        SDirectory
    };
}

export function getMcPageData(): McPageData
{
    const { Server, UrlData, SfTemplateName }  = PageDataSettings;
    return buildMcPageData({
        UrlData,
        Server,
        SfTemplateName,
        UtmCampaign: ValueGetters.UtmCampaign.value,
        UtmMedium: ValueGetters.UtmMedium.value,
        UtmSource: ValueGetters.UtmSource.value
    });
}
import { isNil, type Nil, isArray } from "@mcwd/typescript-type-guards";
import { readOneTrustCookie } from "../onetrust/readOneTrustCookie.js";
import { getGoogleConsentDefaults } from "./getGoogleConsentDefaults.js";
import { getGoogleConsentUpdatesFromOneTrust } from "./getGoogleConsentFromOneTrust.js";
import { OneTrustCategoryId } from "../onetrust/OneTrustCategoryIdToNameMapping.js";
import { EnabledOneTrustCategoryIdState } from "../onetrust/EnabledOneTrustCategoryIdState.js";
import { onetrustVerboseLogger } from "../onetrust/oneTrustLogger.js";
import type { OptanonConsentCookieObject } from "../onetrust/OptanonContentCookieTypes.js";
import type { GoogleConsentStatus } from "./GoogleConsentTypes.js";
import { logGoogleConsentGlobalVarState } from "./readGoogleConsentData.js";

type WindowWithDataLayer = typeof window & { dataLayer: object[] };
(window as WindowWithDataLayer).dataLayer = (window as WindowWithDataLayer).dataLayer || [];

const gtag: Gtag.Gtag = function gtag() {
  // eslint-disable-next-line prefer-rest-params
  (window as WindowWithDataLayer).dataLayer.push(arguments);
};
window.gtag = gtag;

let enabledOneTrustCategoryIdState: EnabledOneTrustCategoryIdState | Nil;
function updateGoogleConsent(enabledCategoryIds: OneTrustCategoryId[] | Nil, ) {
  const consentUpdate = getGoogleConsentUpdatesFromOneTrust(enabledCategoryIds);
  if (consentUpdate) {
    gtag('consent', 'update', consentUpdate);
    (window as WindowWithDataLayer).dataLayer.push({
      event: "ConsentUpdated",
      UpdateData: consentUpdate
    });
  }
}

function setupGoogleConsentUpdateHandler({ enableConsentUpdateEvent }: { enableConsentUpdateEvent: boolean }) {
  let isFirstLoad = true; 
  window.addEventListener("OneTrustGroupsUpdated", (event) => {
    const initialIsFirst = isFirstLoad;
    isFirstLoad = false;
    const ids = (event as CustomEvent<OneTrustCategoryId[] | Nil>).detail;
    if (isNil(ids)) {
      throw new Error("Unexpected null property 'detail' in event 'OneTrustGroupsUpdated'");
    }
    if (isNil(enabledOneTrustCategoryIdState)) {
      enabledOneTrustCategoryIdState = new EnabledOneTrustCategoryIdState(ids);
      logGoogleConsentGlobalVarState();
    }
    else if (enabledOneTrustCategoryIdState.checkUpdateEqualsCurrent(ids) === false) {
      if (enableConsentUpdateEvent) {
        updateGoogleConsent(ids);
      }
      setTimeout(() => {
        logGoogleConsentGlobalVarState();
      }, 500);
      enabledOneTrustCategoryIdState.CurrentIds = ids;
    }
    else if (initialIsFirst) {
      logGoogleConsentGlobalVarState();
    }
    else {
      onetrustVerboseLogger.debug("OneTrust Groups did not change");
    }
  });
}

export interface SetupGoogleConsentParams {
  readDefaultsFromCookie: boolean,
  addRegionDefaults: boolean,
  globalDefaultValue: GoogleConsentStatus;
  waitForUpdateInMs?: number | Nil,
  enableConsentUpdateEvent: boolean
}

export async function setupGoogleConsent({ readDefaultsFromCookie, addRegionDefaults, globalDefaultValue, waitForUpdateInMs, enableConsentUpdateEvent }: SetupGoogleConsentParams) {
  const onetrustCookie = readDefaultsFromCookie ? (await readOneTrustCookie()) ?? {} : null;
  const { groups: cookieGroups = [], AwaitingReconsent: isAwaitingReconsent = false } = (onetrustCookie ?? {});
  
  let consentCookie: OptanonConsentCookieObject | Nil = null;

  if (readDefaultsFromCookie) {
    if (isAwaitingReconsent !== true && isArray(cookieGroups) && cookieGroups.length > 0) {
      const enabledGroups = cookieGroups
        .filter(g => g.enabled === true)
        .map(g => g.id as OneTrustCategoryId);
      enabledOneTrustCategoryIdState = new EnabledOneTrustCategoryIdState(enabledGroups);
      consentCookie = onetrustCookie;
    }
  }
  else if (addRegionDefaults === false) {
    enabledOneTrustCategoryIdState = globalDefaultValue === 'granted'
      ? new EnabledOneTrustCategoryIdState(OneTrustCategoryId)
      : new EnabledOneTrustCategoryIdState([]);
  }
  const consentDefaults: Gtag.ConsentParams[] = getGoogleConsentDefaults({
    addRegionDefaults,
    consentCookie,
    globalDefaultValue,
    waitForUpdateInMs
  });

  for (const consentDefaultSetting of consentDefaults) {
    gtag('consent', 'default', consentDefaultSetting);
  }
  
  setupGoogleConsentUpdateHandler({ enableConsentUpdateEvent });
}
import { isArray, isNil, isObject, isString, type Nil } from "@mcwd/typescript-type-guards";
import { type GoogleConsentOptions, GoogleConsentType, GoogleConsentTypeToCookieCategoryMapping, GoogleConsentTypeToCookieCategoryIdMapping } from "./GoogleConsentTypes.js";
import type { OneTrustValues } from "../onetrust/getOneTrustValues.js";
import { OneTrustCategoryIdToNameMapping, type OneTrustCategoryId } from "../onetrust/OneTrustCategoryIdToNameMapping.js";

export function getGoogleConsentUpdatesFromOneTrust(enabledOneTrustGroupIds: OneTrustCategoryId[] | Nil): Gtag.ConsentParams | Nil {
  if (isArray(enabledOneTrustGroupIds) && enabledOneTrustGroupIds.length > 0) {
    const enabledGroupNames = enabledOneTrustGroupIds.map(id => {
      return OneTrustCategoryIdToNameMapping[id];
    }).filter(g => isString(g));
    if (enabledGroupNames.length === 0) {
      return null;
    }
    const userConsentSettings = {} as Partial<GoogleConsentOptions>;
    let hasUpdate = false;
    for (const type of GoogleConsentType) {
      const matchingOneTrustCategory = GoogleConsentTypeToCookieCategoryMapping[type];
      if (enabledGroupNames.includes(matchingOneTrustCategory)) {
        userConsentSettings[type] = 'granted';
        hasUpdate = true;
      }
    }
    if (hasUpdate) {
      return userConsentSettings;
    }
  }
  return null;
}

export function getGoogleConsentFromOneTrust(onetrustValues: OneTrustValues | Nil): GoogleConsentOptions | Nil {
  if (isObject(onetrustValues) && isArray(onetrustValues.groups) && onetrustValues.groups.length > 0 && (onetrustValues.AwaitingReconsent !== true)) {
    const userConsentSettings = {} as GoogleConsentOptions;
    for (const type of GoogleConsentType) {
      const matchingOneTrustCategoryId = GoogleConsentTypeToCookieCategoryIdMapping[type];
      const onetrustGroups = isNil(matchingOneTrustCategoryId)
        ? null
        : onetrustValues.groups.find(g => g.id === matchingOneTrustCategoryId);
      const isEnabled = (onetrustGroups?.enabled) === true;
      userConsentSettings[type] = isEnabled ? 'granted' : 'denied';
    }
    return userConsentSettings;
  }
  return null;
}